<template>
  <div class="card">
    <div class="container">
      <b-modal ref="editOthersBannersModal" hide-footer>
        <editOtherBanners
          :editMode="editMode"
          :editingOtherBanners="currentOthersBanners"
          @closeEditOtherBanners="toggleEditOthersBanners()"
          @editOtherBannersSuccess="editOthersBannersSuccess()"
          @closeAddOtherBanners="toggleAddOthersBanners()"
          @addOtherBannersSuccess="addOthersBannersSuccess()"
        ></editOtherBanners>
      </b-modal>
      <div class="row">
        <div class="col">
          <br />
          <h2>Other Banners</h2>
        </div>
      </div>
      <div class="row">
        <button
          @click="toggleAddOthersBanners()"
          type="button"
          class="btn btn-success"
        >
          Add
        </button>
      </div>
      <div class="row">
        <br />

        <DataBlocks
          @itemSelected="itemSelected"
          :data="items"
          :fields="['BannerType']"
        />
      </div>
    </div>
  </div>
</template>
<script>
import editOtherBanners from "../../components/editOtherBanners";

import DataBlocks from "@/components/custom/dataBlocks.vue";
import VueNotifications from "vue-notifications";
import { Datatable } from "../../mdb/components/Datatable";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });
export default {
  data() {
    return {
      items: [],
      bannerTypes: [],
      addOthersBannersOpen: false,
      editOtherBanners: false,
      currentOthersBanners: {},
      editMode: false,
    };
  },
  components: {
    editOtherBanners,
    Datatable,
    DataBlocks,
  },
  created: function () {
    this.getOthersBanners();
    this.addOthersBannersOpen = false;
    this.editOthersBannersOpen = false;
  },
  methods: {
    getImageSource(data) {
      if (!this.isNull(data) && !this.isNull(data.Image)) {
        return this.$store.state.cdnUrl + data.Image;
      }
      return "";
    },
    isNull: function (obj) {
      if (typeof obj === "undefined" || obj === null || obj === "null") {
        return true;
      }
      return false;
    },
    getBannerTypes(data) {
      var payload = {
        data: this.editingOtherBanners,
        success: (response) => {
          
          this.bannerTypes = response.data;

          for (const iterator of data) {
            var bannerType = response.data.find(
              (g) => g.Id === iterator.BannerTypeId
            );

            if (bannerType) {
              iterator.BannerType = bannerType.Description;
            } else {
              iterator.bannerType = "Un-assigned";
            }
          }
          this.items = data;
        },
        error: (error) => {
          
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getOtherBannerTypes", payload);
    },
    getOthersBanners() {
      var payload = {
        success: (response) => {
          this.getBannerTypes(response.data);
        },
        error: (error) => {
          
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getOtherBanners", payload);
    },
    editItem(OthersBanners) {
      this.editMode = true;
      this.toggleEditOthersBanners();
      this.currentOthersBanners = OthersBanners;
    },
    itemSelected(item) {
      this.editMode = true;
      this.currentOthersBanners = item;
      if (this.editOthersBannersOpen) {
        this.$refs.editOthersBannersModal.hide();
      } else {
        this.$refs.editOthersBannersModal.show();
      }
      this.editOthersBannersOpen = !this.editOthersBannersOpen;
    },
    toggleAddOthersBanners() {
      this.editMode = false;
      this.currentOthersBanners = {};
      if (this.editOthersBannersOpen) {
        this.$refs.editOthersBannersModal.hide();
      } else {
        this.$refs.editOthersBannersModal.show();
      }
      this.editOthersBannersOpen = !this.editOthersBannersOpen;
    },
    addOthersBannersSuccess() {
      this.toggleAddOthersBanners();
      miniToastr["success"]("OthersBanners Added", "Success", 1000, null);
      this.getOthersBanners();
    },
    toggleEditOthersBanners() {
      this.editMode = true;
      if (this.editOthersBannersOpen) {
        this.$refs.editOthersBannersModal.hide();
      } else {
        this.$refs.editOthersBannersModal.show();
      }
      this.editOthersBannersOpen = !this.editOthersBannersOpen;
    },
    editOthersBannersSuccess() {
      this.toggleEditOthersBanners();
      miniToastr["success"]("OthersBanners Edited", "Success", 1000, null);
      this.getOthersBanners();
    },
  },
};
</script>
<style scoped>
.OthersBannersThumbnail {
  height: 50px;
}
</style>
