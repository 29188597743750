<template>
  <div class="container">
    <h2>{{ editMode ? "Edit" : "Add" }} other banner</h2>
    <button @click="save()" type="button" class="btn btn-success">Save</button>

    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>

    <button @click="deleteOtherBanners()" type="button" class="btn btn-danger">
      Delete
    </button>

    <div class="row">
      <div class="col-md-4">
        <label class for="BannerType">Banner Type</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingOtherBanners.BannerTypeId"
        >
          <option
            v-for="option in bannerTypes"
            :key="option.Id"
            :value="option.Id"
          >
            {{ option.Description }}
          </option>
        </select>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-md-4">
        <label class for="Heading">Heading</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="editingOtherBanners.Heading"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-2">
        <label class for="Copy">Heading Colour</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingOtherBanners.HeadingColour"
        >
          <option value="#000000">Black</option>
          <option value="#ffffff">White</option>
          <option value="#17a2b8">Teal</option>
           <option value="#002c5f">Navy</option>
        </select>
      </div>

      <div class="col-md-2">
        <label class for="Heading">Heading Fontsize</label>
        <input
          class="form-control"
          id="Heading"
          name="Heading"
          v-model="editingOtherBanners.HeadingFontSize"
          placeholder=" "
          tabindex="0"
          type="number"
        />
      </div>
      <div class="col-md-2">
        <label class for="Copy">Heading Colour Mobile</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingOtherBanners.HeadingColourMobile"
        >
          <option value="#000000">Black</option>
          <option value="#ffffff">White</option>
          <option value="#17a2b8">Teal</option>
           <option value="#002c5f">Navy</option>
        </select>
      </div>

      <div class="col-md-2">
        <label class for="Copy">Heading Mobile Fontsize</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingOtherBanners.HeadingFontSizeMobile"
          placeholder=" "
          tabindex="0"
          type="number"
        />
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-md-2">
        <label class for="Copy">Copy</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingOtherBanners.Copy"
          placeholder=" "
          tabindex="0"
          type="text"
        />
        <br>
          <label class for="Copy">Copy Botswana</label>
         <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingOtherBanners.CopyBotswana"
          placeholder="Copy Botswana"
          tabindex="0"
          type="text"
        />
        <br>
           <label class for="Copy">Copy Namibia</label>
         <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingOtherBanners.CopyNamibia"
          placeholder="Copy Namibia "
          tabindex="0"
          type="text"
        />
        <br>
           <label class for="Copy">Copy Swaziland</label>
         <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingOtherBanners.CopySwaziland"
          placeholder="Copy Swaziland "
          tabindex="0"
          type="text"
        />
      </div>

      <div class="col-md-2">
        <label class for="Copy">Copy Colour</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingOtherBanners.CopyColour"
        >
          <option value="#000000">Black</option>
          <option value="#ffffff">White</option>
          <option value="#17a2b8">Teal</option>
          <option value="#002c5f">Navy</option>
        </select>
      </div>

      <div class="col-md-2">
        <label class for="Copy">Copy Fontsize</label>
        <input
          class="form-control"
          id="Copy"
          name="Copy"
          v-model="editingOtherBanners.CopyFontSize"
          placeholder=" "
          tabindex="0"
          type="number"
        />
      </div>
      <div class="col-md-2">
        <label class for="Copy">Content Location</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingOtherBanners.ContendLocation"
        >
          <option value="top">Top</option>
          <option value="center">Center</option>
          <option value="bottom">Bottom</option>
        </select>
      </div>
      <div class="col-md-2">
        <label class for="Copy">Content Location Mobile</label>

        <select
          style="display: block !important"
          class="form-control"
          v-model="editingOtherBanners.ContendLocationMobile"
        >
          <option value="top">Top</option>
          <option value="center">Center</option>
          <option value="bottom">Bottom</option>
        </select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <ImageUploader label="Image" v-model="editingOtherBanners.Image" />
      </div>
      <div class="col-md-6">
        <ImageUploader
          label="Mobile Image"
          v-model="editingOtherBanners.MobileImage"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <label class for="Link">Link</label>
        <input
          class="form-control"
          id="Link"
          name="Link"
          v-model="editingOtherBanners.Link"
          placeholder=" "
          tabindex="0"
          type="text"
        />

        <ImageUploader
          label="Link Image"
          :dark="true"
          v-model="editingOtherBanners.LinkImage"
        />
      </div>
      <div class="col-md-3">
        <label class for="LinkFriendly">Link Friendly</label>
        <input
          class="form-control"
          id="LinkFriendly"
          name="LinkFriendly"
          v-model="editingOtherBanners.LinkFriendly"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>

      <div class="col-md-3">
        <label class="" for="ShowText">ShowText</label>
        <br />
        <b-form-checkbox
          v-model="editingOtherBanners.ShowText"
        ></b-form-checkbox>
        <div class=""><small></small></div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <label class for="Link">Link 2</label>
        <input
          class="form-control"
          id="Link"
          name="Link"
          v-model="editingOtherBanners.Link2"
          placeholder=" "
          tabindex="0"
          type="text"
        />

        <ImageUploader
          label="Link2 Image"
          :dark="true"
          v-model="editingOtherBanners.Link2Image"
        />
      </div>
      <div class="col-md-3">
        <label class for="LinkFriendly">Link2 Friendly</label>
        <input
          class="form-control"
          id="LinkFriendly"
          name="LinkFriendly"
          v-model="editingOtherBanners.Link2Friendly"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
      <div class="col-md-4">
        <ImageUploader
          label="Logo Image"
          v-model="editingOtherBanners.LogoImage"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <ImageUploader
          :dark="true"
          label="Warranty Image One"
          v-model="editingOtherBanners.WarrantyImageOne"
        />
      </div>
      <div class="col-md-6">
        <ImageUploader
          :dark="true"
          label="Warranty Image Two"
          v-model="editingOtherBanners.WarrantyImageTwo"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <label class="" for="ShowText">ShowTandC</label>
        <br />
        <b-form-checkbox
          v-model="editingOtherBanners.ShowTandC"
        ></b-form-checkbox>
      </div>
      <div class="col-md-4">
        <label class="" for="ShowText">Show Enquiry Form</label>
        <br />
        <b-form-checkbox
          v-model="editingOtherBanners.ShowEnquiryForm"
        ></b-form-checkbox>
      </div>

      <div class="col-md-4">
        <label class="" for="Order">Enquiry Form Heading</label>
        <br />
        <input
          class="form-control"
          id="EnquiryFormHeading"
          name="EnquiryFormHeading"
          v-model="editingOtherBanners.EnquiryFormHeading"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>

      <div class="col-md-4">
        <label class="" for="Order">Order</label>
        <br />
        <input
          class="form-control"
          id="Order"
          name="Order"
          v-model="editingOtherBanners.Order"
          placeholder=" "
          tabindex="0"
          type="number"
        />
      </div>
      <div class="col-md-4">
        <label class for="LastUpdated">LastUpdated</label>
        <input
          class="form-control"
          id="LastUpdated"
          name="LastUpdated"
          v-model="editingOtherBanners.LastUpdated"
          placeholder=" "
          tabindex="0"
          type="text"
        />
      </div>
    </div>

    <br />
    <button @click="save()" type="button" class="btn btn-success">Save</button>
    <button @click="toggleView()" type="button" class="btn btn-warning">
      Cancel
    </button>
    <br />
    <br />
  </div>
</template>
<script>
import _ from "lodash";
import ImageUploader from "@/components/custom/ImageUploader.vue";
import miniToastr from "mini-toastr"; // https://github.com/se-panfilov/mini-toastr
const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

export default {
  name: "EditOtherBanners",
  props: {
    options: {
      type: Array,
    },
  },
  data() {
    return {
      imageBytes: {},
      selectedFile: {},
      originalData: {},
      modifiedData: {},
      uploadedImageData: {},
      uploadedImageUrl: null,
      editingImage: false,
      bannerTypes: null,
    };
  },
  components: {
    ImageUploader,
  },
  created() {
    this.getBannerTypes();
    if (this.editMode) {
      this.originalData = JSON.parse(JSON.stringify(this.editingOtherBanners));
      this.ImageBytes = this.editingOtherBanners.Image;
    }
  },

  props: ["editingOtherBanners", "editMode"],

  methods: {
    toggleView: function () {
      this.uploadedImageUrl = null;
      this.$emit("closeEditOtherBanners");
    },
    getBannerTypes() {
      var payload = {
        data: this.editingOtherBanners,
        success: (response) => {
          this.bannerTypes = response.data;
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("getOtherBannerTypes", payload);
    },
    save: function () {
      var data = JSON.parse(JSON.stringify(this.editingOtherBanners));
      var payload = {
        data: data,
        image: this.uploadedImageData,
        success: (response) => {
          if (this.editMode) {
            this.$emit("editOtherBannersSuccess");
          } else {
            this.$emit("addOtherBannersSuccess");
          }
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      if (this.editMode) {
        this.$store.dispatch("editOtherBanners", payload);
      } else {
        this.$store.dispatch("addOtherBanners", payload);
      }
    },
    imageUploaded: function (file) {
      this.editingOtherBanners.Image = file;
    },
    imageUploadedMobile: function (file) {
      this.editingOtherBanners.MobileImage = file;
    },
    deleteOtherBanners: function () {
      var payload = {
        data: this.editingOtherBanners,
        success: (response) => {
          this.$emit("editOtherBannersSuccess");
        },
        error: (error) => {
          miniToastr["error"](error, "Error", 1000, null);
        },
      };
      this.$store.dispatch("deleteOtherBanners", payload);
    },
  },
};
</script> 
<style scoped>
.previewImage {
  max-height: 100px;
}
</style> 
